import styled, { css } from 'styled-components';
import { Form, FormControl } from 'react-bootstrap';

import { COLOR } from '../../Constants';
import { mobile } from '../../Utils/layout.utils';

export const ModalTitle = styled.h3`
  color: var(--current-light-black, #000);
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.96px;
  padding: 0;
  margin: 0;
`;

export const FormLabel = styled(Form.Label)`
  color: var(--neutral-black, #000);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: -0.14px;
`;

export const FormInput = styled(Form.Control)<{ isError: boolean }>`
  border-radius: 4px;
  border-color: ${props =>
          props.isError ? `${COLOR.ERROR}` : `${COLOR.BORDER}`};

  &::placeholder {
    color: #c2c2c2;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }

  &:focus {
    box-shadow: none !important;
    border-color: ${COLOR.PRIMARY};
  }

  ${mobile(css`
    &::placeholder {
      font-size: 12px;
      font-weight: 400;
      line-height: 100%;
    }
  `)};
`;

export const FormControlStyled = styled(FormControl)<{ isError: boolean }>`
  border-color: ${props =>
          props.isError ? `${COLOR.ERROR}` : `${COLOR.BORDER}`};

  &::placeholder {
    color: #c2c2c2;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: -0.16px;
  }

  &:focus {
    box-shadow: none !important;
    border-color: ${COLOR.PRIMARY};
  }
`;
