import React, { useState } from 'react';
import { Dropdown, Menu } from 'antd';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import _ from 'lodash';
import moment from 'moment';

import { toProperCase } from '../../Utils';
import toolTipIcon from '../../Assets/Icon/tooltip-icon.svg';
import {
	ActionButton,
	ActionLabel,
	ActiveUserLabel,
	ExtendButton,
	Input,
	NotificationLabel,
	TableContainer,
	TableData,
	TableHead,
	TableHeader,
	TableRowHead,
	TooltipIcon,
	TooltipSpan,
} from './UserTable.styled';
import { ITableData, ITableProps } from './UserTable.types';
import { FlexDisplaySmallGap } from '../NavigationBar/NavigationBar.styled';
import { ACTION_TYPE } from '../../Screens/AdminPanel/AdminPanel.screen';

export const EXTEND_ACCESS_TYPE = {
	SUBSCRIPTION: 'SUBSCRIPTION',
	PORTAL: 'PORTAL',
};

const renderTableHeadWithTooltips = (label: string, tooltipInfo: string) => {
	return (
		<FlexDisplaySmallGap>
			<div>{label}</div>
			<OverlayTrigger
				placement="top"
				overlay={
					<Tooltip id="tooltip">
						<TooltipSpan>{tooltipInfo}</TooltipSpan>
					</Tooltip>
				}>
				<TooltipIcon src={toolTipIcon} alt="tooltip icon" />
			</OverlayTrigger>
		</FlexDisplaySmallGap>
	);
};

const renderTableHeader = (): React.JSX.Element => {
	return (
		<thead>
			<TableRowHead>
				<TableHeader colSpan={8}>Basic Information</TableHeader>
				<TableHeader colSpan={6}>User Subscription Management</TableHeader>
			</TableRowHead>
			<TableRowHead>
				<TableHead>Email</TableHead>
				<TableHead>Joined</TableHead>
				<TableHead>Active</TableHead>
				<TableHead>Last Activity</TableHead>
				<TableHead>Action</TableHead>
				<TableHead>
					{renderTableHeadWithTooltips('Newsletter', 'News and Updates')}
				</TableHead>
				<TableHead>
					{renderTableHeadWithTooltips(
						'Watchlist',
						'Watchlist Report Announcement'
					)}
				</TableHead>
				<TableHead>
					{renderTableHeadWithTooltips('Tips', 'Tips and Tutorials')}
				</TableHead>
				<TableHead>Subscription</TableHead>
				<TableHead>Change Subscription</TableHead>
				<TableHead>Subscription Access Expiration</TableHead>
				<TableHead>Extended Subscription Access (days)</TableHead>
				<TableHead>Portal Access Expiration</TableHead>
				<TableHead>Extended Portal Access (days)</TableHead>
			</TableRowHead>
		</thead>
	);
};

const RenderExtendAccess = (props: {
	defaultProps: ITableProps;
	userData: ITableData;
	type: string;
}) => {
	const { defaultProps, userData, type } = props;
	const [extend, setExtend] = useState<string | number>('');

	let accessExpiration = _.get(
		userData,
		'portalAccessExpiration',
		moment().format('YYYY-MM-DD HH:mm:ss')
	);

	if (type === EXTEND_ACCESS_TYPE.SUBSCRIPTION) {
		accessExpiration = _.get(
			userData,
			'accessExpiration',
			moment().format('YYYY-MM-DD HH:mm:ss')
		);
	}

	const handleExtend = async () => {
		await defaultProps.handleExtendSubscription(
			userData.id,
			Number(extend),
			accessExpiration,
			type
		);

		setExtend('');
	};

	return (
		<FlexDisplaySmallGap>
			<Input
				placeholder="numbers"
				defaultValue={extend}
				type="number"
				min={1}
				onChange={e => setExtend(e.target.value)}
			/>
			<ExtendButton
				disabled={extend === '' || extend <= 0}
				onClick={handleExtend}>
				Extend
			</ExtendButton>
		</FlexDisplaySmallGap>
	);
};

const renderActionButton = (props: ITableProps, userData: ITableData) => {
	const menu = (
		<Menu>
			<Menu.Item
				key={Math.random()}
				onClick={() => {
					props.showModal(ACTION_TYPE.ACTIVATE_ACCOUNT, {
						userId: userData.id,
						is_active: true,
						currentStatus: userData.active,
					});
				}}>
				<ActionLabel className="text-decoration-none">Activate</ActionLabel>
			</Menu.Item>

			<Menu.Item
				key={Math.random()}
				onClick={() => {
					props.showModal(ACTION_TYPE.ACTIVATE_ACCOUNT, {
						userId: userData.id,
						is_active: false,
						currentStatus: userData.active,
					});
				}}>
				<ActionLabel className="text-decoration-none">Deactivate</ActionLabel>
			</Menu.Item>

			<Menu.Item
				key={Math.random()}
				onClick={() => {
					props.showModal(ACTION_TYPE.DELETE_ACCOUNT, userData.id);
				}}>
				<ActionLabel className="text-decoration-none">Delete</ActionLabel>
			</Menu.Item>

			<Menu.Item
				key={Math.random()}
				onClick={() => {
					props.showModal(ACTION_TYPE.FORCE_PASSWORD, userData.id);
				}}>
				<ActionLabel className="text-decoration-none">
					Force Password
				</ActionLabel>
			</Menu.Item>
		</Menu>
	);

	return (
		<Dropdown overlay={menu} placement="bottomRight">
			<ActionButton>Action</ActionButton>
		</Dropdown>
	);
};

const renderChangeSubscriptionButton = (
	props: ITableProps,
	userData: ITableData
) => {
	const items = _.get(props, 'subscriptionFilter', []);
	const menu = () => {
		return (
			<Menu>
				{items?.map(item => {
					if (item.name === 'All') {
						return null;
					}
					return (
						<Menu.Item
							key={Math.random()}
							onClick={() => {
								props.showModal(ACTION_TYPE.CHANGE_SUBSCRIPTION, {
									userId: userData.id,
									subscription_id: item.id,
									current_subscription: userData.subscription_id,
								});
							}}>
							<ActionLabel>{item.name}</ActionLabel>
						</Menu.Item>
					);
				})}
			</Menu>
		);
	};

	return (
		<Dropdown overlay={menu()} placement="topLeft">
			<ActionButton>Change</ActionButton>
		</Dropdown>
	);
};
const renderTableBody = (props: ITableProps): React.JSX.Element => {
	const { data } = props;
	return (
		<tbody>
			{data.map((item, index) => (
				<tr key={index}>
					<TableData>
						<b>{item?.email}</b>
					</TableData>
					<TableData>{item?.joined}</TableData>
					<TableData>
						<ActiveUserLabel isActive={item?.active}>
							{item?.active ? 'Yes' : 'No'}
						</ActiveUserLabel>
					</TableData>
					<TableData>{item.lastActivity}</TableData>
					<TableData>{renderActionButton(props, item)}</TableData>
					<TableData>
						<NotificationLabel isActive={item?.newsLetter}>
							{item?.newsLetter ? 'On' : 'Off'}
						</NotificationLabel>
					</TableData>
					<TableData>
						<NotificationLabel isActive={item?.watchList}>
							{item?.watchList ? 'On' : 'Off'}
						</NotificationLabel>
					</TableData>
					<TableData>
						<NotificationLabel isActive={item?.tips}>
							{item?.tips ? 'On' : 'Off'}
						</NotificationLabel>
					</TableData>
					<TableData>{toProperCase(item?.subscription)}</TableData>
					<TableData>{renderChangeSubscriptionButton(props, item)}</TableData>
					<TableData>{item?.accessExpiration}</TableData>
					<TableData>
						<RenderExtendAccess
							defaultProps={props}
							userData={item}
							type={EXTEND_ACCESS_TYPE.SUBSCRIPTION}
						/>
					</TableData>
					<TableData>{item?.portalAccessExpiration}</TableData>
					<TableData>
						<RenderExtendAccess
							defaultProps={props}
							userData={item}
							type={EXTEND_ACCESS_TYPE.SUBSCRIPTION}
						/>
					</TableData>
				</tr>
			))}
		</tbody>
	);
};

const UserTableComponent = (props: ITableProps): React.JSX.Element => {
	return (
		<div>
			<TableContainer>
				{renderTableHeader()}
				{renderTableBody(props)}
			</TableContainer>
		</div>
	);
};

export default UserTableComponent;
