import styled from 'styled-components';
import { Row } from 'react-bootstrap';

export const Container = styled.div`
	padding: 2vw 5vw;
`;

export const Tab = styled.div<{ isSelected: boolean }>`
	border-radius: 4px;
	background: ${({ isSelected }) => (isSelected ? '#225fb0' : '#FFF')};
	color: ${({ isSelected }) => (isSelected ? '#fff' : '#4E4E4E')};
	padding: 6px 12px;
	font-size: 12px;
	font-style: normal;
	font-weight: 700;
	line-height: 20px;
	cursor: pointer;
`;

export const RowContainer = styled(Row)`
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	background: #fff;
	padding: 1vh;
`;

export const TabMenu = styled.div<{ isSelected?: boolean }>`
	color: ${({ isSelected }) => (isSelected ? '#2D2D2D' : '#4e4e4e')};
	font-size: 16px;
	font-style: normal;
	font-weight: ${({ isSelected }) => (isSelected ? 700 : 500)};
	line-height: 20px;
	border-bottom: ${({ isSelected }) =>
		isSelected ? '3px solid #225FB0;' : 'none'};
	padding-top: 1vh;
	padding-bottom: 1vh;
	cursor: pointer;
`;
