import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const AuthorizationModal = styled(Modal)`
	border-radius: 4px;
	box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.04);
`;

export const ModalBody = styled.div`
	padding: 2.5vw;
`;
