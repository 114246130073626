import { Table } from 'react-bootstrap';
import styled from 'styled-components';

import { IconStyled } from '../NavigationBar/NavigationBar.styled';

export const TooltipIcon = styled(IconStyled)`
	width: 0.8vw;
	padding: 0;
`;

export const TableContainer = styled(Table)`
	border-radius: 4px;
	border: 1px solid #e0e0e0;
	background: #fff;
`;

export const TableRowHead = styled.tr``;

export const TableHead = styled.th`
	color: #4e4e4e;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	background-color: #eaeaea !important;
`;

export const TableHeader = styled(TableHead)`
	font-size: 12px;
`;

export const ActiveUserLabel = styled.span<{ isActive: boolean }>`
	color: ${({ isActive }) => (isActive ? '#107C10' : '#AC0101')};
	font-weight: 600;
`;

export const NotificationLabel = styled.span<{ isActive: boolean }>`
	color: ${({ isActive }) => (isActive ? '#107C10' : '#C8C8C8')};
	font-weight: 600;
`;

export const TableData = styled.td`
	color: #4e4e4e;
	font-size: 10px;
	font-style: normal;
	font-weight: 400;
	line-height: 20px;
`;

export const TooltipSpan = styled.span`
	color: #fff;
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: 20px;
`;

export const Input = styled.input`
	border-radius: 4px;
	border: 1px solid #c2c2c2;
	background: #fff;
	padding-left: 0.4rem;

	&::placeholder {
		color: #c2c2c2;
		font-style: normal;
		font-weight: 400;
		line-height: 150%;
		letter-spacing: -0.16px;
	}

	&:focus {
		box-shadow: none !important;
		border-color: #c2c2c2 !important;
	}
`;

export const ExtendButton = styled.button<{ disabled?: boolean }>`
	border-radius: 4px;
	background: ${props => (props.disabled ? '#ccc' : '#316bb7')};
	color: #fff;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	border: none;
`;

export const ActionLabel = styled.span`
	color: #4e4e4e;
	font-size: 10px;
	font-style: normal;
	font-weight: 600;
	line-height: 20px;
	padding: 0 !important;
	margin: 0 !important;
`;

export const ActionButton = styled.button`
	padding: 0 15px;
	border-radius: 4px;
	border: 0.5px solid #e0e0e0;
	background-color: white;
`;
