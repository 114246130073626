import { useState } from 'react';
import { ToastContainer } from 'react-toastify';

import { SignIn } from '../index';
import { AuthorizationModal, ModalBody } from './Authorization.styled';
import { IAuthorizationProps } from './Authorization.types';
import { AUTHORIZATION_STEP } from '../../Constants';

export const MODAL_BACKDROP = {
	STATIC: 'STATIC',
	DYNAMIC: true,
};

const AuthorizationComponent = (props: IAuthorizationProps) => {
	const {
		isOpenAuthorization,
		setIsOpenAuthorization,
		authorizationStep,
		setAuthorizationStep,
		setDiscoverTutorialModal,
		setIsAdmin,
		setIsLoggedIn,
	} = props;

	const [ , setModalBackdrop] = useState<string | boolean>(
		MODAL_BACKDROP.DYNAMIC,
	);

	const changeStep = (step: string) => {
		setAuthorizationStep(step);
	};

	const changeBackdropBehaviour = (backdrop: string | boolean) => {
		setModalBackdrop(backdrop);
	};

	const showDiscoverTutorialModal = () => {
		setDiscoverTutorialModal(true);
	};

	const onHide = () => setIsOpenAuthorization(false);

	const stepProps = () => ({
		changeStep,
		changeBackdropBehaviour,
		showDiscoverTutorialModal,
		onHide,
		setIsAdmin,
		setIsLoggedIn,
	});

	const authorizationModalProps = () => ({
		backdrop: 'static',
		show: isOpenAuthorization,
		onHide,
		centered: true,
		keyboard: false
	});

	return (
		<AuthorizationModal {...authorizationModalProps()}>
			<ToastContainer />
			<ModalBody>
				{authorizationStep === AUTHORIZATION_STEP.LOGIN && (
					<SignIn {...stepProps()} />
				)}
			</ModalBody>
		</AuthorizationModal>
	);
};

export default AuthorizationComponent;
