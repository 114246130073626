import styled, { css } from 'styled-components';
import { FaGear, FaArrowRightFromBracket } from 'react-icons/fa6';
import { Navbar } from 'react-bootstrap';

import { mobile } from '../../Utils/layout.utils';

export const FlexDisplay = styled.div`
  display: flex;
  gap: 1.5vw;
`;

export const ContainerNavBar = styled.div`
  width: 100%;
  box-shadow: 2px 2px 4px 0 rgba(0, 0, 0, 0.04);
`;

export const ContainerGearIcon = styled.div`
  border-radius: 4px;
  background: #f5f5f5;
  padding: 0.8vh;
  cursor: pointer;
`;

export const GearIcon = styled(FaGear)`
  font-size: 15px;
  color: #62656f;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SignOutIcon = styled(FaArrowRightFromBracket)`
  font-size: 15px;
  color: #62656f;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Brand = styled(Navbar.Brand)`
  color: #000;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
`;

export const FlexDisplaySpaceBetween = styled(FlexDisplay)`
  justify-content: space-between;
  align-items: center;
  align-content: center;
`;

export const FlexDisplayMediumGap = styled(FlexDisplay)`
  gap: 1vw;
  align-items: center;
`;

export const FlexDisplaySmallGap = styled(FlexDisplay)`
  gap: 0.3vw;
  align-items: center;
`;

export const IconStyled = styled.img`
  width: 2.3vw;
  color: black;
  font-size: 35.5rem;
  padding-bottom: 1vh;

  ${mobile(css`
    width: 3vw;
    padding-bottom: 0;
  `)};
`;
