import {
	css,
	DefaultTheme,
	FlattenInterpolation,
	ThemedStyledProps,
} from 'styled-components';
import { AnyIfEmpty } from 'react-redux';

const size = {
	mobile: '425px',
	tablet: '930px',
	laptop: '1024px',
	desktop: '2560px',
	largeDesktop: '6000px',
};

export const mobile = (
	inner: FlattenInterpolation<
		ThemedStyledProps<NonNullable<unknown>, AnyIfEmpty<DefaultTheme>>
	>
) => css`
	@media (max-width: ${size.mobile}) {
		${inner};
	}
`;
