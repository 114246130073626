import React from 'react';

import {
	Brand,
	ContainerGearIcon,
	ContainerNavBar,
	FlexDisplayMediumGap,
	FlexDisplaySpaceBetween,
	GearIcon,
	SignOutIcon,
} from '../NavigationBar.styled';
import { INavigationBar } from '../NavigationBar.types';
import { ROUTES } from '../../../Constants/routes';

const NavigationDesktopComponent = (props: INavigationBar) => {
	const {
		setIsOpenAuthorization,
		isLoggedIn,
	} = props;

	const handleClick = () => {
		if (!isLoggedIn) {
			setIsOpenAuthorization(true);
		}

		if (isLoggedIn) {
			localStorage.clear();
			location.reload();
		}
	};

	const renderButton = () => {
		if (isLoggedIn) {
			return <SignOutIcon className='nav-icon' onClick={handleClick} />;
		}

		return <GearIcon className='nav-icon' onClick={handleClick} />;
	};

	return (
		<ContainerNavBar className='py-3 px-5'>
			<FlexDisplaySpaceBetween>
				<FlexDisplayMediumGap>
					<Brand href={ROUTES.ROOT}>FS Admin</Brand>
				</FlexDisplayMediumGap>
				<FlexDisplaySpaceBetween>
					<ContainerGearIcon>
						{renderButton()}
					</ContainerGearIcon>
				</FlexDisplaySpaceBetween>
			</FlexDisplaySpaceBetween>
		</ContainerNavBar>
	);
};

export default NavigationDesktopComponent;
