import React, { useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FaEye, FaEyeSlash } from 'react-icons/fa';
import { AxiosInstance } from 'axios';
import { toast } from 'react-toastify';
import _ from 'lodash';

import { FormControlStyled, FormInput, FormLabel, ModalTitle } from './SignIn.styled';
import { DataRequest, IAuthorizationElementProps, ISignInResponseType } from '../Authorization/Authorization.types';
import { ERROR_CODES } from '../../Constants';
import servicesRequest, { SIGN_IN_URL } from '../../Config/services.config';
import { Button } from '../index';

export interface ErrorResponse {
	message: string;
	status_code: number;
}
const SignInComponent = (props: IAuthorizationElementProps) => {
	const [isLoading, setIsLoading] = useState(false);
	const [showPassword, setShowPassword] = useState(false);

	const togglePasswordVisibility = () => setShowPassword(prevShow => !prevShow);

	const { onHide } = props;
	const {
		handleSubmit,
		control,
		formState: { errors },
	} = useForm({
		defaultValues: {
			email: '',
			password: '',
		},
	});

	const onSubmit = async (data: DataRequest) => {
		setIsLoading(true);
		try {
			const request: AxiosInstance = servicesRequest();
			const response: ISignInResponseType = await request.post(
				SIGN_IN_URL,
				data,
			);

			const token: string = _.get(response, 'data.data.token.token', '');
			const roleResponse: string = _.get(response, 'data.data.user.role', '');

			localStorage.setItem('role', roleResponse);
			localStorage.setItem('token', token);

			onHide();
			props.setIsLoggedIn(true);
			location.reload();
		} catch (error: unknown) {
			const err = error as ErrorResponse;
			const statusCode = _.get(err, 'response.data.status_code');

			if (statusCode === ERROR_CODES.VALIDATION_ERROR) {
				toast.error(
					'There is something wrong with the input. Please check and try again',
				);
			} else {
				toast.error('Your username or password is not found.');
			}
		} finally {
			setIsLoading(false);
		}
	};

	return (
		<React.Fragment>
			<ModalTitle>Welcome to FinSent</ModalTitle>
			<div className='mt-4'>
				<Form>
					<Form.Group className='mb-3'>
						<FormLabel>Email</FormLabel>
						<Controller
							name='email'
							control={control}
							rules={{
								required: 'Please type your email',
							}}
							render={({ field: { onChange, value } }) => (
								<FormInput
									type='email'
									label='Email'
									placeholder='mail@website.com'
									onChange={onChange}
									value={value}
									isError={!!errors?.email}
								/>
							)}
						/>
						<small className='text-red'>
							{errors?.email && errors.email?.message}
						</small>
					</Form.Group>

					<Form.Group className='mb-3'>
						<FormLabel>Password</FormLabel>
						<InputGroup>
							<Controller
								name='password'
								control={control}
								rules={{
									required: 'Please type your password',
								}}
								render={({ field: { onChange, value } }) => (
									<FormControlStyled
										label='Password'
										value={value}
										onChange={onChange}
										isError={!!errors?.password}
										type={showPassword ? 'text' : 'password'}
										placeholder='Min 8 characters'
									/>
								)}
							/>
							<span
								className='input-group-text cursor-pointer'
								onClick={togglePasswordVisibility}>
								{showPassword ? <FaEyeSlash /> : <FaEye />}
							</span>
						</InputGroup>
						<small className='text-red'>
							{errors?.password && errors.password?.message}
						</small>
					</Form.Group>
					<Button
						isLoading={isLoading}
						label='Sign In'
						onClick={handleSubmit(onSubmit)}
					/>
				</Form>
			</div>
		</React.Fragment>
	);
};

export default SignInComponent;
