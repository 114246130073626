import React from 'react';

import { IButtonProps } from './Button.types';
import { ButtonStyled } from './Button.styled';

export const BUTTON_TYPE: {
	SECONDARY: string;
	PRIMARY: string;
	DANGER: string;
} = {
	PRIMARY: 'PRIMARY',
	SECONDARY: 'SECONDARY',
	DANGER: 'DANGER',
};

const ButtonComponent = (props: IButtonProps): React.JSX.Element => {
	const {
		isLoading = false,
		label,
		onClick,
		htmlType,
		disabled = false,
		kind = BUTTON_TYPE.PRIMARY,
		rounded,
		icon,
	} = props;

	const buttonProps = () => ({
		loading: isLoading,
		width: props?.width,
		mobileWidth: props?.mobileWidth,
		disabled,
		kind,
		rounded,
	});

	if (htmlType === 'submit') {
		return (
			<ButtonStyled htmlType={htmlType} {...buttonProps()}>
				{label} {icon}
			</ButtonStyled>
		);
	}

	return (
		<ButtonStyled onClick={onClick} {...buttonProps()}>
			{label} {icon}
		</ButtonStyled>
	);
};

export default ButtonComponent;
