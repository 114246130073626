import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
	ArcElement,
	CategoryScale,
	Chart as ChartJS,
	Legend,
	LinearScale,
	LineElement,
	PointElement,
	Title,
	Tooltip,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';
import { toast } from 'react-toastify';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import { Button, Dropdown, Modal } from 'antd';
import moment from 'moment';

import { Container, RowContainer, TabMenu } from './AdminPanel.styled';
import {
	IAdminPanelScreenState,
	IPageViewGraphData,
	ISubscription,
	ISubscriptionData,
} from './AdminPanel.types';
import { ROLE } from '../../Constants';
import servicesRequest, {
	ADMIN_ACCOUNT_TYPE_URL,
	ADMIN_ACTIVE_USERS_URL,
	ADMIN_PAGE_VIEWS_URL,
	ADMIN_URL,
	ADMIN_USERS_URL,
} from '../../Config/services.config';
import { RenderAdminGraph, RenderUserTable } from './Component';
import {
	FlexDisplay,
	FlexDisplaySmallGap,
} from '../../Components/NavigationBar/NavigationBar.styled';
import { IAuthorizationProps } from '../../Components/Authorization/Authorization.types';
import { EXTEND_ACCESS_TYPE } from '../../Components/UserTable/UserTable.component';

ChartJS.register(
	ArcElement,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend
);

const GRAPH_TAB = {
	PAGE_VIEWS: 'Page Views',
	ACTIVE_USERS: 'Active Users',
	ACCOUNT_TYPE: 'Account Type',
};

const DATE_FILTER = {
	ONE_MONTH: 'one-month',
	THREE_MONTH: 'three-month',
	SIX_MONTH: 'six-month',
	ONE_YEAR: 'one-year',
};

const getYears = () => {
	const currentYear = moment().year();

	return Array.from({ length: 5 }, (unused, index) => currentYear - index);
};

const createYearItems = (urlParams: string) => {
	const listOfYear = getYears();
	return listOfYear.map((item, index) => {
		return {
			key: index,
			label: (
				<a
					className="text-decoration-none"
					rel="noopener noreferrer"
					href={`?${urlParams}=${item}`}>
					{item}
				</a>
			),
		};
	});
};

const renderAccountTypeGraph = (
	data: ISubscriptionData | null,
	year: number | string
) => {
	if (!_.isNull(data)) {
		return (
			<>
				<Dropdown
					menu={{ items: createYearItems('account-type-year') }}
					placement="bottomLeft">
					<Button>{year}</Button>
				</Dropdown>
				<Pie
					options={{
						responsive: true,
						plugins: {
							legend: {
								display: true,
								position: 'right',
							},
						},
					}}
					data={data}
					width={50}
					height={50}
				/>
			</>
		);
	}

	return <></>;
};

export const ACTION_TYPE = {
	CHANGE_SUBSCRIPTION: 'CHANGE_SUBSCRIPTION',
	ACTIVATE_ACCOUNT: 'ACTIVATE_ACCOUNT',
	DELETE_ACCOUNT: 'DELETE_ACCOUNT',
	FORCE_PASSWORD: 'FORCE_PASSWORD',
};

const AdminPanelScreen = (props: IAuthorizationProps) => {
	const [graphTab, setGraphTab] = useState(GRAPH_TAB.PAGE_VIEWS);
	const [pageViews, setPageViews] = useState<IPageViewGraphData | null>(null);
	const [activeUsers, setActiveUsers] = useState<IPageViewGraphData | null>(
		null
	);
	const [users, setUsers] = useState(null);
	const [accountType, setAccountType] = useState<ISubscriptionData | null>(
		null
	);
	const [subscriptionFilter, setSubscriptionFilter] = useState<
		ISubscription[] | null
	>(null);
	const [open, setOpen] = useState(false);
	const [actionType, setActionType] = useState('');
	const [tempPayloadAction, setTempPayloadAction] = useState<any>(null);
	const [dateRange, setDateRange] = useState('one-month');

	const handleChangeTab = (tab: string) => {
		setGraphTab(tab);
	};

	const token = localStorage.getItem('token');
	const isAdmin = localStorage.getItem('role') !== ROLE.MEMBER;

	const [searchParams] = useSearchParams();

	const page = searchParams.get('page') || 1;
	const selectedSubscription = searchParams.get('subscriptionFilter') || '';
	const accountTypeYear =
		searchParams.get('account-type-year') || moment().year();
	const pageViewsYear = searchParams.get('page-views-year') || moment().year();

	useEffect(() => {
		if (!token || !isAdmin) {
			localStorage.clear();
		}
	}, []);

	const fetchPageViews = async () => {
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(ADMIN_PAGE_VIEWS_URL + `?year=${pageViewsYear}`);

			setPageViews(data);
		} catch (error) {
			toast.error(
				'There is something went wrong while fetch page views. Please check and try again'
			);
		}
	};

	const fetchActiveUsers = async () => {
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(
				ADMIN_ACTIVE_USERS_URL + `?date_range=${dateRange}`
			);

			setActiveUsers(data);
		} catch (error) {
			toast.error(
				'There is something went wrong while fetch active users. Please check and try again'
			);
		}
	};

	const fetchUsers = async () => {
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(
				ADMIN_USERS_URL +
					`?subscription_id=${selectedSubscription}&page=${page}`
			);

			setUsers(data.users);

			const defaultSubscriptionFilter = [
				{
					id: '',
					name: 'All',
				},
				...data.subscriptions,
			];
			setSubscriptionFilter(defaultSubscriptionFilter);
		} catch (error) {
			toast.error(
				'There is something went wrong while fetch users. Please check and try again'
			);
		}
	};

	const fetchAccountType = async () => {
		try {
			const request = servicesRequest();
			const {
				data: { data },
			} = await request.get(
				ADMIN_ACCOUNT_TYPE_URL + `?year=${accountTypeYear}`
			);

			setAccountType(data);
		} catch (error) {
			toast.error(
				'There is something went wrong while fetch subscription data. Please check and try again'
			);
		}
	};

	useEffect(() => {
		if (token) {
			fetchActiveUsers().catch(console.error);
		}
	}, [dateRange]);

	useEffect(() => {
		if (token) {
			fetchAccountType().catch(console.error);
		}
	}, [accountTypeYear]);

	useEffect(() => {
		if (token) {
			fetchPageViews().catch(console.error);
		}
	}, [pageViewsYear]);

	useEffect(() => {
		if (token) {
			fetchUsers().catch(console.error);
		}
	}, [page, selectedSubscription]);

	useEffect(() => {
		if (!token) {
			props.setIsOpenAuthorization(true);
		}
	}, [dateRange, accountTypeYear, pageViewsYear, selectedSubscription]);

	const handleActive = async (payload: {
		userId: number;
		is_active: boolean;
		currentStatus: boolean;
	}) => {
		const { userId, is_active, currentStatus } = payload;

		try {
			if (currentStatus === is_active) {
				toast.error(
					`User status is already ${currentStatus ? 'active' : 'nonactive'}`
				);
			}

			if (currentStatus !== is_active) {
				const request = servicesRequest();
				await request.put(ADMIN_USERS_URL + `/${userId}/set-active`, {
					is_active,
				});

				toast.success('User status updated');
				fetchUsers().catch(console.error);
			}
		} catch (error) {
			toast.error(
				'There is something went wrong while update user status. Please try again'
			);
		}
	};

	const handleDelete = async (userId: number) => {
		try {
			const request = servicesRequest();
			await request.delete(ADMIN_USERS_URL + `/${userId}`);

			toast.success('User deleted');
			fetchUsers().catch(console.error);
		} catch (error) {
			toast.error(
				'There is something went wrong while try delete user. Please try again'
			);
		}
	};

	const handleForcePassword = async (userId: number) => {
		try {
			const request = servicesRequest();
			await request.post(ADMIN_USERS_URL + `/${userId}/force-password`);

			toast.success('Force password successful');
			fetchUsers().catch(console.error);
		} catch (error) {
			toast.error('There is something went wrong . Please try again');
		}
	};

	const handleChangeSubscription = async (payload: {
		userId: number;
		subscription_id: number;
		current_subscription: number;
	}) => {
		const { userId, subscription_id, current_subscription } = payload;
		try {
			if (current_subscription === subscription_id) {
				toast.error('User already has same subscription plan');
			}

			if (current_subscription !== subscription_id) {
				const request = servicesRequest();
				await request.post(
					ADMIN_USERS_URL + `/${userId}/change-subscription/${subscription_id}`
				);

				toast.success('User subscription plan updated');
				fetchUsers().catch(console.error);
			}
		} catch (error) {
			toast.error(
				'There is something went wrong while update user subscription plan. Please try again'
			);
		}
	};

	const handleExtendSubscription = async (
		userId: number,
		extendDays: number,
		currentExpiration: string,
		type: string
	) => {
		try {
			if (!_.isNumber(extendDays) || extendDays === 0) {
				toast.error('Please check again your input');
			}

			if (_.isNumber(extendDays) && extendDays !== 0) {
				let date = currentExpiration;
				if (currentExpiration === '-') {
					date = moment().format('YYYY-MM-DD HH:mm:ss');
				}

				const current = moment(new Date(date));
				const extendDate = current.add(3, 'days');
				const extend = extendDate.format('YYYY-MM-DD HH:mm:ss');

				let url = ADMIN_URL + `/subscription/portal-page/${userId}/extend`;

				if (type === EXTEND_ACCESS_TYPE.SUBSCRIPTION) {
					url = ADMIN_URL + `/subscription/expiration/${userId}/extend`;
				}

				const request = servicesRequest();
				await request.post(url, {
					extend,
				});

				toast.success('User access extended');
				fetchUsers().catch(console.error);
			}
		} catch (error) {
			toast.error(
				'There is something went wrong while update user access. Please try again'
			);
		}
	};

	const showModal = (typeOfAction: string, payload: any) => {
		setActionType(typeOfAction);
		setTempPayloadAction(payload);
		setOpen(true);
	};

	const handleCancel = () => {
		setActionType('');
		setTempPayloadAction(null);
		setOpen(false);
	};

	const handleOk = () => {
		switch (actionType) {
			case ACTION_TYPE.CHANGE_SUBSCRIPTION:
				handleChangeSubscription(tempPayloadAction).catch(console.error);
				break;
			case ACTION_TYPE.ACTIVATE_ACCOUNT:
				handleActive(tempPayloadAction).catch(console.error);
				break;
			case ACTION_TYPE.DELETE_ACCOUNT:
				handleDelete(tempPayloadAction).catch(console.error);
				break;
			case ACTION_TYPE.FORCE_PASSWORD:
				handleForcePassword(tempPayloadAction).catch(console.error);
				break;
			default:
				break;
		}

		handleCancel();
	};

	const childrenProps: IAdminPanelScreenState = {
		tableSelectedTab: selectedSubscription,
		graphTab,
		setGraphTab,
		users,
		subscriptionFilter,
		page,
		handleExtendSubscription,
		showModal,
	};

	if (token && isAdmin) {
		return (
			<Container>
				<Row>
					<Col md={8}>
						<FlexDisplay>
							<TabMenu
								isSelected={graphTab === GRAPH_TAB.PAGE_VIEWS}
								onClick={() => handleChangeTab(GRAPH_TAB.PAGE_VIEWS)}>
								Page Views
							</TabMenu>
							<TabMenu
								isSelected={graphTab === GRAPH_TAB.ACTIVE_USERS}
								onClick={() => handleChangeTab(GRAPH_TAB.ACTIVE_USERS)}>
								Active Users
							</TabMenu>
						</FlexDisplay>
					</Col>
					<Col md={4}>
						<TabMenu isSelected={true} className="w-30">
							Account Type
						</TabMenu>
					</Col>
				</Row>
				<div className="mt-3 mb-5">
					<RowContainer className="m-0 py-4">
						<Col md={8}>
							{graphTab === GRAPH_TAB.PAGE_VIEWS && !_.isNull(pageViews) && (
								<>
									<Dropdown
										menu={{ items: createYearItems('page-views-year') }}
										placement="bottomLeft">
										<Button>{pageViewsYear}</Button>
									</Dropdown>
									<RenderAdminGraph data={pageViews} />
								</>
							)}

							{graphTab === GRAPH_TAB.ACTIVE_USERS &&
								!_.isNull(activeUsers) && (
									<>
										<FlexDisplaySmallGap>
											<Button
												type={
													dateRange === DATE_FILTER.ONE_MONTH
														? 'primary'
														: 'default'
												}
												size="small"
												onClick={() => {
													setDateRange(DATE_FILTER.ONE_MONTH);
												}}>
												1M
											</Button>
											<Button
												type={
													dateRange === DATE_FILTER.THREE_MONTH
														? 'primary'
														: 'default'
												}
												size="small"
												onClick={() => {
													setDateRange(DATE_FILTER.THREE_MONTH);
												}}>
												3M
											</Button>
											<Button
												type={
													dateRange === DATE_FILTER.SIX_MONTH
														? 'primary'
														: 'default'
												}
												size="small"
												onClick={() => {
													setDateRange(DATE_FILTER.SIX_MONTH);
												}}>
												6M
											</Button>
											<Button
												type={
													dateRange === DATE_FILTER.ONE_YEAR
														? 'primary'
														: 'default'
												}
												size="small"
												onClick={() => {
													setDateRange(DATE_FILTER.ONE_YEAR);
												}}>
												1Y
											</Button>
										</FlexDisplaySmallGap>
										<RenderAdminGraph data={activeUsers} />
									</>
								)}
						</Col>
						<Col md={4}>
							{renderAccountTypeGraph(accountType, accountTypeYear)}
						</Col>
					</RowContainer>
				</div>
				<RenderUserTable {...childrenProps} />
				<Modal
					title="Confirmation"
					open={open}
					onOk={handleOk}
					okText="Confirm"
					onCancel={handleCancel}>
					<p>Are you sure to do this action?</p>
				</Modal>
			</Container>
		);
	}

	return null;
};

export default AdminPanelScreen;
