export const COLOR = {
	PRIMARY: '#225FB0',
	SECONDARY: '#56783E',
	ERROR: '#A5492D',
	BORDER: '#DEE2E6',
};

export const AUTHORIZATION_STEP = {
	LOGIN: 'LOGIN',
	REGISTER: 'REGISTER',
	FIND_PASSWORD: 'FIND_PASSWORD',
};

export const SUBSCRIPTION_TYPE = {
	DEMO: '_xst_u_d',
	BASIC: '_xst_u_b',
	STANDARD: '_xst_u_s',
	DEVELOPER: '_xst_u_de',
	ENTERPRISE: '_xst_u_e',
};

export const LOCAL_STORAGE_VARIABLE = {
	FIRST_TIME_USER: 'first_time_user',
	SUBSCRIPTION_TYPE: '_xst_u',
};

export const ERROR_CODES = {
	VALIDATION_ERROR: 422,
	UNAUTHORIZED_ERROR: 401,
	VERIFICATION_ERROR: 403,
};

export const ROLE = {
	MEMBER: 'member',
};

export const SCREEN_SIZE = {
	MOBILE: 'MOBILE',
	TABLET: 'TABLET',
	LAPTOP: 'LAPTOP',
	DESKTOP: 'DESKTOP',
	LARGE_DESKTOP: 'LARGE_DESKTOP',
};
