/* eslint-disable */
// @ts-nocheck
import axios, { AxiosError, AxiosRequestConfig, AxiosResponseHeaders } from 'axios';
import _ from 'lodash';

import { ErrorResponse } from '../Components/VerificationModal/VerificationModal.types';

export const SERVICES_URL = process.env.REACT_APP_API_URL;
export const SIGN_IN_URL = SERVICES_URL + '/auth/login';
export const ADMIN_URL = SERVICES_URL + '/admin';
export const ADMIN_PAGE_VIEWS_URL = ADMIN_URL + '/dashboard/page-views';
export const ADMIN_ACTIVE_USERS_URL = ADMIN_URL + '/dashboard/active-users';
export const ADMIN_USERS_URL = ADMIN_URL + '/users';
export const ADMIN_ACCOUNT_TYPE_URL = ADMIN_URL + '/dashboard/active-user-subscriptions';

const requestHandler = (request: AxiosRequestConfig) => {
	const token: string = localStorage.getItem('token');
	request.headers.Authorization = `Bearer ${token}`;

	return request;
};

const responseHandler = (response: AxiosResponseHeaders) => {
	const res = response as ErrorResponse;
	const statusCode = _.get(res, 'response.data.status_code');

	if (statusCode === 401) {
		localStorage.clear();
	}

	return response;
};

const errorHandler = (error: AxiosError) => {
	const err = error as ErrorResponse;
	const statusCode = _.get(err, 'response.data.status_code');

	if (statusCode === 401) {
		localStorage.clear();
	} else {
		return Promise.reject(error);
	}
};

const servicesRequest = (type = '') => {
	const contentType =
		type === 'formData' ? 'multipart/form-data' : 'application/json';
	const request = axios.create({
		headers: {
			'Content-Type': contentType,
		},
	});

	request.interceptors.request.use(
		request => requestHandler(request),
		(error: AxiosError) => errorHandler(error),
	);

	request.interceptors.response.use(
		response => responseHandler(response),
		(error: AxiosError) => errorHandler(error),
	);

	return request;
};

export default servicesRequest;
