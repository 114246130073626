import React from 'react';
import { Line } from 'react-chartjs-2';
import { Pagination } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import { useNavigate } from 'react-router';

import {
	IAdminPanelScreenState,
	IPageViewGraphData,
	ISubscription,
	IUserData,
} from '../AdminPanel.types';
import { UserTable } from '../../../Components';
import { Tab } from '../AdminPanel.styled';
import { FlexDisplaySmallGap } from '../../../Components/NavigationBar/NavigationBar.styled';

export const RenderAdminGraph = (props: { data: IPageViewGraphData }) => {
	const {
		data: { labels, datasets },
	} = props;
	const options = {
		responsive: true,
		plugins: {
			legend: {
				position: 'top' as const,
			},
			title: {
				display: false,
			},
		},
	};

	const mappingDatasets = datasets.map(
		(item: {
			label: string;
			data: number[];
			borderColor: string;
			backgroundColor: string;
		}) => {
			return {
				label: item.label,
				data: item.data,
				borderColor: item.borderColor,
				backgroundColor: item.backgroundColor,
			};
		}
	);

	const graphData = {
		labels,
		datasets: mappingDatasets,
	};

	return <Line options={options} data={graphData} />;
};

const RenderTableTab = (props: IAdminPanelScreenState) => {
	const navigate = useNavigate();

	const subscriptionFilter: ISubscription[] | null = _.get(
		props,
		'subscriptionFilter',
		null
	);

	if (!_.isNull(subscriptionFilter)) {
		return (
			<div className="mb-2">
				<FlexDisplaySmallGap>
					{subscriptionFilter.map((item, index) => (
						<Tab
							isSelected={String(item?.id) === props?.tableSelectedTab}
							key={index}
							onClick={() => {
								navigate(`?page=1&subscriptionFilter=${item.id}`);
							}}>
							{item?.name}
						</Tab>
					))}
				</FlexDisplaySmallGap>
			</div>
		);
	}

	return <></>;
};

export const RenderUserTable = (props: IAdminPanelScreenState) => {
	const meta = _.get(props, 'users.meta', []);
	const data = _.get(props, 'users.data', []);

	const navigate = useNavigate();

	const reformatData = data?.map((item: IUserData) => {
		let newsLetter = false;
		let watchList = false;
		let tips = false;

		const userNotificationPreferences = item.user_notification_preference;

		if (userNotificationPreferences.length > 0) {
			const findTipsSetting = userNotificationPreferences.find(
				x => x.notification_preference_id === 1
			);
			const findNewsSettings = userNotificationPreferences.find(
				x => x.notification_preference_id === 2
			);
			const findWatchlistSettings = userNotificationPreferences.find(
				x => x.notification_preference_id === 3
			);

			tips = _.get(findTipsSetting, 'turn_on', false);
			newsLetter = _.get(findNewsSettings, 'turn_on', false);
			watchList = _.get(findWatchlistSettings, 'turn_on', false);
		}

		let lastActivity = _.get(item, 'user_latest_activity.created_at', '');
		lastActivity = _.isEmpty(lastActivity)
			? '-'
			: moment(lastActivity).format('YYYY-MM-DD HH:mm:ss');

		let joined = _.get(item, 'created_at', new Date());
		joined = _.isEmpty(joined)
			? '-'
			: moment(joined).format('YYYY-MM-DD HH:mm:ss');

		let accessExpiration = _.get(
			item,
			'user_active_subscription.end_date',
			new Date()
		);
		accessExpiration = _.isEmpty(accessExpiration)
			? '-'
			: moment(accessExpiration).format('YYYY-MM-DD HH:mm:ss');

		let portalAccessExpiration: string | null = _.get(
			item,
			'user_latest_subscription_extend.portal_access_end',
			null
		);
		portalAccessExpiration = _.isNull(portalAccessExpiration)
			? '-'
			: moment(portalAccessExpiration).format('YYYY-MM-DD HH:mm:ss');

		return {
			id: item.id,
			email: item.email,
			joined,
			active: item.is_active,
			lastActivity,
			newsLetter,
			watchList,
			tips,
			subscription_id: item.user_active_subscription.subscription.id,
			subscription: item.user_active_subscription.subscription.name,
			accessExpiration,
			portalAccessExpiration,
		};
	});

	const currentPage = _.get(meta, 'current_page', 1);
	const total = _.get(meta, 'total', 1);

	return (
		<React.Fragment>
			<RenderTableTab {...props} />
			<UserTable {...props} data={reformatData} />
			<Pagination
				defaultCurrent={currentPage}
				total={total}
				showSizeChanger={false}
				onChange={page => {
					navigate(`?page=${page}`);
				}}
			/>
		</React.Fragment>
	);
};
