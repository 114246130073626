import React from 'react';

import { IAuthorizationProps } from '../Authorization/Authorization.types';
import NavigationDesktopComponent from './Desktop';

const NavigationBarComponent = (props: IAuthorizationProps) => {
	const token = localStorage.getItem('token');
	const isLoggedIn = !!token;

	const navigationProps = () => ({
		...props,
		isLoggedIn,
	});

	return (
		<NavigationDesktopComponent {...navigationProps()} />
	);
};

export default NavigationBarComponent;
